import {FC} from 'react'
import {useRecordContext} from 'react-admin'

interface CustomNotificationFieldProps {
    source: string;
}

const CustomNotificationField: FC<CustomNotificationFieldProps> = ({source}: any) => {
  const record = useRecordContext()
  if (!record) return null

  let titleObj
  try {
    titleObj = JSON.parse(record[source])
  } catch (e) {
    console.error('Error parsing title JSON', e)
    return <span>Error</span>
  }

  // Return the English title or a fallback
  return <span>{titleObj?.en || ''}</span>
}

export default CustomNotificationField
