import {Toolbar, useNotify, useRedirect} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {Button} from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const MyToolbar = (props: any) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const {handleSubmit} = useFormContext()
  const onSave = async (data: any) => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    console.log(data.selectedDateTime)
    const dataToSend = {
      title: {en: data.title_en, fr: data.title_fr, uk: data.title_uk},
      body: {en: data.message_en, fr: data.message_fr, uk: data.message_uk},
      subtitle: {
        en: data.description_en,
        fr: data.description_fr,
        uk: data.description_uk,
      },
      segment: data.message_category,
      selectedDateTime: props.selectedDateTime
        ? dayjs(props.selectedDateTime)
            .tz('Europe/Paris')
            .format('YYYY-MM-DD HH:mm:ss [GMT]Z')
        : null,
    }

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append(
      'Authorization',
      localStorage.getItem('Authorization') ?? '',
    )

    if (dataToSend) {
      await fetch('api/onesignal/push', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(dataToSend),
      })
    }

    notify('Submission successful', {type: 'success'})
  }

  return (
    <Toolbar {...props}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSave)}>
        Send
      </Button>
    </Toolbar>
  )
}
