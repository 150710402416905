import {Button, Grid, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import {
  DateField,
  Filter,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
} from 'react-admin'
import {useNavigate, useParams} from 'react-router-dom'
import {DataGridWithIndex} from '../../components'
import {CommonSidePanel} from '../../components/layout/CommonSidePanel'
import {CustomList} from '../../components/layout/CustomList'
import {exporter} from '../utils/exporter'
import {InfoTab} from './tabs'
import CustomNotificationField from './fields/customNotificationField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker/DateTimePicker'
import {MyToolbar} from './components/form-bar'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs'
import dayjs from 'dayjs'
import './notifications.css'

const fetchNotificationData = async (id: string, dataProvider: any) => {
  const entity: any = await dataProvider.getOne('notifications', {id: id})

  return entity
}

const navigateToNotificationCreate = async () => {}

const downloadNotificationsData = () => {
  let collectedData: any[] = []
  const maxPerFetch: number = 100
  let totalRows: number

  const fetchBatch = async (page: number = 0) => {
    try {
      const authorizationToken = localStorage.getItem('Authorization')
      if (!authorizationToken) {
        throw new Error('Authorization token not found')
      }

      const myHeaders = new Headers({
        Authorization: authorizationToken,
        'Content-Type': 'application/json',
      })

      const offset = maxPerFetch * page
      const response = await fetch(
        `/api/notifications?` +
          new URLSearchParams({
            limit: `${maxPerFetch}`,
            offset: `${offset}`,
            sort: 'createdAt,DESC',
          }),
        {
          method: 'GET',
          headers: myHeaders,
        },
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const rawData = await response.json()
      const {data, total} = rawData
      collectedData = [...collectedData, ...data]
      totalRows = total
    } catch (error) {
      console.error('Fetch error:', error)
    }

    if (collectedData.length < totalRows) {
      await fetchBatch(page + 1)
    } else {
      exporter('notifications')(collectedData)
    }
  }

  fetchBatch().catch(err => console.error('Error during export:', err))
}

const ListActions = () => (
  <TopToolbar>
    <Button variant="text" onClick={downloadNotificationsData}>
      Export
    </Button>
    <Button variant="text" onClick={navigateToNotificationCreate}>
      Create
    </Button>
  </TopToolbar>
)

export const NotificationsList = ({...props}) => {
  const [type, setType] = useState('history')
  const params = useParams()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState<any>(null)
  const [dateTime, setDateTime] = useState<any>(dayjs())
  const NotificationFilter = (props: any) => {
    return (
      <Filter {...props}>
        <TextInput label="Full Name" source="fullName" />
        <TextInput label="Currency" source="trnCurrencyCode" />
        <NumberInput label="Max Amount" source="maxAmount" />
        <NumberInput label="Min Amount" source="minAmount" />
      </Filter>
    )
  }
  useEffect(() => {
    const id = params['*']

    if (id) {
      fetchNotificationData(id, dataProvider).then(entity => {
        setRecord(entity)
      })
    }
  }, [params['*'], dataProvider])

  const handleRowClick = async (notificationId: any) => {
    return Promise.resolve(`/notifications/${notificationId}`)
  }

  const sidePanelTabsList = [{name: 'General', TabComponent: InfoTab}]

  return (
    <>
      <div>
        <ToggleButtonGroup color="primary" value={type} exclusive>
          <ToggleButton
            onClick={() => {
              setType('history')
            }}
            value="history">
            History
          </ToggleButton>
          <ToggleButton
            onClick={() => {
              setType('create')
            }}
            value="create">
            Create
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {type == 'history' && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CustomList
              {...props}
              // customActions={['export']}
              actions={<ListActions />}
              filters={<NotificationFilter />}
              empty={false}
              sort={{field: 'createdAt', order: 'DESC'}}>
              <DataGridWithIndex {...props} rowClick={handleRowClick}>
                <TextField source="id" textAlign="left" label="Id" />
                <CustomNotificationField source="title" />
                <CustomNotificationField source="message" />
                <CustomNotificationField source="subtitle" />
                <DateField source="createdAt" textAlign="left" showTime />
              </DataGridWithIndex>
            </CustomList>
          </Grid>
          <Grid item xs={4} md={3}>
            <Box display="flex" alignItems="flex-start" mt={8} height="100%">
              <CommonSidePanel
                title="Notification"
                record={record}
                list={sidePanelTabsList}
                togglePanel={() => {
                  setRecord(undefined)
                  navigate('/notifications', {replace: true})
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {type == 'create' && (
        <div style={{flex: 1, flexDirection: 'row'}}>
          <SimpleForm
            className="application-form-container"
            toolbar={<MyToolbar selectedDateTime={dateTime} />}>
            <div
              style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
              <div className="input-field">
                <TextInput source="title_en" validate={[required()]} />
                <TextInput source="title_uk" validate={[required()]} />
                <TextInput source="title_fr" validate={[required()]} />
              </div>
              <div className="input-field">
                <TextInput
                  source="message_en"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
                <TextInput
                  source="message_uk"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
                <TextInput
                  source="message_fr"
                  validate={[required()]}
                  multiline
                  fullWidth
                />
              </div>
              <div className="input-field">
                <TextInput
                  source="description_en"
                  validate={[]}
                  multiline
                  fullWidth
                />
                <TextInput
                  source="description_uk"
                  validate={[]}
                  multiline
                  fullWidth
                />
                <TextInput
                  source="description_fr"
                  validate={[]}
                  multiline
                  fullWidth
                />
              </div>
              {/* <div className="input-field">
                  <TextInput source="link" validate={[]} fullWidth />
                </div> */}
              <div className="input-field">
                <RadioButtonGroupInput
                  source="sendTime"
                  choices={[
                    {id: 'immediately', name: 'Immediately'},
                    {id: 'planned', name: 'Planned Sending'},
                  ]}
                />
              </div>
              <div className="input-field">
                <RadioButtonGroupInput
                  source="message_category"
                  choices={[
                    {id: 'News', name: 'News'},
                    {id: 'Promotions', name: 'Promotions'},
                    {id: 'Mission', name: 'Mission'},
                  ]}
                />
              </div>
              <FormDataConsumer>
                {({formData, ...rest}) =>
                  formData.sendTime === 'planned' && (
                    <div className="input-field">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          className="dt"
                          value={dateTime}
                          onChange={setDateTime}
                        />
                      </LocalizationProvider>
                    </div>
                  )
                }
              </FormDataConsumer>
            </div>
          </SimpleForm>
        </div>
      )}
    </>
  )
}
