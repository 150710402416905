import '@fontsource/inter/700.css'
import {styled} from '@mui/system'
import React, {useMemo} from 'react'
import {Datagrid, DatagridProps, useListContext} from 'react-admin'
import {CurrentIndexField} from '../fields/indexField'

export const StyledDataGrid = styled(Datagrid)({
  // '& .RaDatagrid-rowOdd': {
  //   backgroundColor: '#ebeae8',
  // },
  backgroundColor: '#FFF',
  borderRadius: 8,
})

export const DataGridWithIndex = React.forwardRef<
  HTMLTableElement,
  DatagridProps
>((props, ref: React.ForwardedRef<HTMLTableElement>) => {
  const {children, data, ...rest} = props
  const {data: rawData} = useListContext()
  const datasource = useMemo(
    () =>
      rawData?.map((dt: any, index: number) => ({
        ...dt,
        __index__: index + 1, // make it 1-based
      })) || [],
    [rawData],
  )

  return (
    <StyledDataGrid
      {...rest}
      sx={{
        '& .RaDatagrid-row': {
          whiteSpace: 'nowrap',
        },
      }}
      style={{zIndex: 0}}
      bulkActionButtons={false}
      ref={ref}
      data={data || datasource}>
      <CurrentIndexField label="#" headerClassName="datagrid-id-col" />
      {children}
    </StyledDataGrid>
  )
})
