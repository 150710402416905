import {Admin, CustomRoutes, Resource} from 'react-admin'
//@ts-ignore
import {Route} from 'react-router'
import {BrowserRouter as Routerr} from 'react-router-dom'
import {Layout} from './components/layout'
import {LoginPage, ProfileEdit} from './pages'
import accounts from './pages/accounts'
import admins from './pages/admins'
import consumers from './pages/consumers'
import corporate from './pages/corporates'
import transactions from './pages/transactions'
import notifications from './pages/notifications'
import {authProvider, dataProvider} from './providers'
import {lightTheme} from './themes'

import {Dashboard} from './pages/dashboard'
import './themes/css/SidePanel.css'
import './themes/css/main.css'

const App = () => {
  return (
    <Routerr>
      <Admin
        loginPage={LoginPage}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={Layout}
        theme={lightTheme}
        requireAuth>
        <CustomRoutes>
          {/* @ts-ignore */}
          <Route path="/my-profile" element={<ProfileEdit />} />
        </CustomRoutes>
        <Resource
          name="consumers"
          {...consumers}
          options={{label: 'Consumers'}}
          recordRepresentation={record =>
            `${record.firstName} ${record.lastName}`
          }
        />
        <Resource
          name="corporates"
          {...corporate}
          options={{label: 'Corporates'}}
        />
        <Resource name="accounts" {...accounts} options={{label: 'Accounts'}} />
        <Resource
          name="transactions"
          {...transactions}
          options={{label: 'Transactions'}}
        />
        <Resource name="admins" {...admins} options={{label: 'Admins'}} />
        <Resource
          name="notifications"
          {...notifications}
          options={{label: 'Notifications'}}
        />
      </Admin>
    </Routerr>
  )
}

export default App
